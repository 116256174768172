// PENDING TO UPDATE FOR VIBAC, Icons should be re-do keeping same canvas sizes (icons only, not logos)
const altByIcon = {
  '3dots': '3dots icon',
  'add': 'Add icon',
  'anchor': 'Anchor icon',
  'back': 'Back icon',
  'check': 'Check icon',
  'chevron-down': 'chevron-down icon',
  'chevron-left': 'chevron-left icon',
  'chevron-right': 'chevron-right icon',
  'chevron-up': 'chevron-up icon',
  'close': 'Close icon',
  'copy': 'Copy icon',
  'dashboard': 'Dashboard icon',
  'eye-closed': 'eye closed icon',
  'eye': 'eye icon',
  'file': 'file icon',
  'info': 'Info icon',
  'list': 'list icon',
  'link': 'link icon',
  'lupa': 'magnifying glass icon',
  'loader': 'loader icon',
  'locked': 'locked icon',
  'menu': 'menu icon',
  'plus': 'plus icon',
  'search': 'search icon',
  'separator': 'separator icon',
  'scroll': 'scroll icon',
  'trash': 'trash icon',
  'unlocked': 'unlocked icon',
  'user': 'user icon',
}

export type TypeMapIcon = keyof typeof altByIcon
export const iconByName = (iconName: TypeMapIcon) => ({
  type: 'svg' as const,
  src: `/images/svg/i--${iconName}.svg`,
  alt: altByIcon[iconName],
  size: { width: 24, height: 24 }
})
