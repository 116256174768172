type RequestCookie = {
  name: string;
  value: string;
}

type TypeUserCookie = {
  token: string,
  exp: string,
  user: any
}

export const getUserDataFromCookie = (userCookieValue: string): TypeUserCookie => JSON.parse(userCookieValue)
export const redirectBaseOnUserRole = (userCookie: RequestCookie, redirect, returnHref = false) => {
  if (!userCookie) return null
  // const userData: TypeUserCookie = getUserDataFromCookie(userCookie.value)
  const href = '/'
  if (returnHref) return href
  if (typeof redirect === 'function' && href) {
    redirect(href)
  }
  // PENDING ADD REMOVE USER
}
