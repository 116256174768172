import { createContext, useCallback, useState } from 'react'
import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { getWeekRange, getWeekNumber, getQuarterMonths} from '@/utils'
import moment from 'moment'

type TypeContextData = {
  filterTaskType: 'day' | 'week' ,
  setFilterTaskType: Dispatch<SetStateAction<string>>
  filterPerformanceType: 'week' | 'month' | 'year' | 'quarter' | 'custom',
  setFilterPerformanceType: Dispatch<SetStateAction<string>>
  currentDay: any,
  setCurrentDay:  Dispatch<SetStateAction<any>>
  customFirstDay: any,
  setCustomFirstDay:  Dispatch<SetStateAction<any>>
  customSecondDay: any,
  setCustomSecondDay:  Dispatch<SetStateAction<any>>
  currentWeek: any
  setCurrentWeek:  Dispatch<SetStateAction<any>>
  rangeWeek: any,
  setWeekRange: Dispatch<SetStateAction<any>>,
  currentMonth: any,
  setCurrentMonth: Dispatch<SetStateAction<any>>,
  currentQuarter: any,
  setCurrentQuarter:  Dispatch<SetStateAction<any>>,
  quarterRange: any,
  setQuarterRange:  Dispatch<SetStateAction<any>>,
  currentYear: any,
  setCurrentYear:  Dispatch<SetStateAction<any>>,
  lastWeekOfYear: number
  setLastWeekOfYear: Dispatch<SetStateAction<any>>,
  updateWeek: (direction: string) => void
  updateDay: (direction: string) => void
  updateCustomFirstDay: (direction: string) => void
  updateCustomSecondDay: (direction: string) => void
  updateMonth: (direction: string) => void
  updateQuarter: (direction: string) => void
  updateYear: (direction: string) => void

}

const initialContextData:TypeContextData = {
  filterTaskType: 'week',
  setFilterTaskType: ()=> {},
  filterPerformanceType: 'week',
  setFilterPerformanceType: ()=> {},
  currentDay: moment().format('ddd, D MMM YYYY'),
  setCurrentDay: ()=> {},
  customFirstDay: moment().format('D MMM YYYY'),
  setCustomFirstDay: ()=> {},
  customSecondDay: moment().format('D MMM YYYY'),
  setCustomSecondDay:  ()=> {},
  currentWeek: getWeekNumber(moment(), 'Monday'),
  setCurrentWeek: () => {},
  rangeWeek: getWeekRange(1),
  setWeekRange: () => {},
  currentMonth: moment().month(),
  setCurrentMonth: () => {},
  currentQuarter: moment().quarter(),
  setCurrentQuarter: () => {},
  quarterRange: getQuarterMonths(moment().year(), moment().quarter()),
  setQuarterRange: () => {},
  currentYear: moment().year(),
  setCurrentYear: () => {},
  lastWeekOfYear: getWeekNumber(moment([2023, 11, 28])),
  setLastWeekOfYear: () => {},
  updateWeek: () => {},
  updateDay: () => {},
  updateCustomFirstDay: () => {},
  updateCustomSecondDay: () => {},
  updateMonth: () => {},
  updateQuarter: () => {},
  updateYear: () => {}
}

interface Props {
  children: ReactNode
}

export const DatesTypeContext = createContext<TypeContextData>(initialContextData)
export const DatesTypeProvider: FC<Props> = ({ children }) => {
  const [filterTaskType, setFilterTaskType] = useState<'day' | 'week'>('week')
  const [filterPerformanceType, setFilterPerformanceType] = useState<'week' | 'month' | 'year' | 'quarter' | 'custom'>('week')
  const [currentDay, setCurrentDay] = useState<any>(moment().format('ddd, D MMM YYYY'))
  const [customFirstDay, setCustomFirstDay] = useState<any>(moment().format('D MMM YYYY'))
  const [customSecondDay, setCustomSecondDay] = useState<any>(moment().format('D MMM YYYY'))
  const [currentWeek, setCurrentWeek] = useState<any>(getWeekNumber(moment(), 'Monday'))
  const [currentMonth, setCurrentMonth] = useState<any>(moment())
  const [currentQuarter, setCurrentQuarter] = useState<any>(moment().quarter())
  const [currentYear, setCurrentYear] = useState<any>(moment().year())
  const [quarterRange, setQuarterRange] = useState<any>(getQuarterMonths(moment().year(), moment().quarter()))
  const [rangeWeek, setWeekRange] = useState<any>(getWeekRange(currentYear, currentWeek ))
  const [lastWeekOfYear, setLastWeekOfYear] = useState<any>(getWeekNumber(moment([currentYear, 11, 28])))

  const updateWeek = useCallback((direction) => {
    if (direction === 'prev') {
      // Gonna go back to prev year
      if (currentWeek === 1) {
        setCurrentYear(prev => {
          const incomingYear = prev - 1
          const incomingLastWeekOfTheYear = getWeekNumber(moment([incomingYear, 11, 28]))
          const incomingWeek = incomingLastWeekOfTheYear
          setLastWeekOfYear(incomingLastWeekOfTheYear)
          setCurrentWeek(incomingWeek)
          setWeekRange(getWeekRange(incomingYear, incomingWeek))
          return incomingYear
        })
        // Prev Week
      } else {
        setCurrentWeek(prev => {
          const incomingWeek = prev - 1
          setWeekRange(getWeekRange(currentYear, incomingWeek))
          return incomingWeek
        })
      }
    } else if (direction === 'next') {
      if (currentWeek === lastWeekOfYear) {
        setCurrentYear(prev => {
          const incomingYear = prev + 1
          setWeekRange(getWeekRange(incomingYear, 1))
          return incomingYear
        })
        setCurrentWeek(1)
      } else {
        setCurrentWeek(prev => {
          const incomingWeek = prev + 1
          setWeekRange(getWeekRange(currentYear, incomingWeek))
          return incomingWeek
        })
      }
    }
  }, [currentWeek, currentYear, lastWeekOfYear])

  const updateDay = useCallback((direction) => {
    if (direction === 'prev') {
      // Gonna go back to prev year
      setCurrentDay((prev)=>{
        const previousDay = moment(prev).subtract(1, 'day')
        const formattedDate = previousDay.format('ddd, D MMM YYYY')
        return formattedDate
      })
    } else if (direction === 'next') {
      setCurrentDay((prev)=>{
        const previousDay = moment(prev).add(1, 'day')
        const formattedDate = previousDay.format('ddd, D MMM YYYY')
        return formattedDate
      })
    }
  }, [])

  const updateCustomFirstDay = useCallback((direction) => {
    if (direction === 'prev') {
      // Gonna go back to prev year
      setCustomFirstDay((prev)=>{
        const previousDay = moment(prev).subtract(1, 'day')
        const formattedDate = previousDay.format('D MMM YYYY')
        return formattedDate
      })
    } else if (direction === 'next') {
      setCustomFirstDay((prev)=>{
        const previousDay = moment(prev).add(1, 'day')
        const formattedDate = previousDay.format('D MMM YYYY')
        return formattedDate
      })
    }
  }, [])

  const updateCustomSecondDay = useCallback((direction) => {
    if (direction === 'prev') {
      // Gonna go back to prev year
      setCustomSecondDay((prev)=>{
        const previousDay = moment(prev).subtract(1, 'day')
        const formattedDate = previousDay.format('D MMM YYYY')
        return formattedDate
      })
    } else if (direction === 'next') {
      setCustomSecondDay((prev)=>{
        const previousDay = moment(prev).add(1, 'day')
        const formattedDate = previousDay.format('D MMM YYYY')
        return formattedDate
      })
    }
  }, [])

  const updateMonth = useCallback((direction) => {
    if (direction === 'prev') {
      setCurrentMonth((prev)=>{
        const previousMonth = moment(prev).subtract(1, 'month')
        return previousMonth
      })
    } else if (direction === 'next') {
      setCurrentMonth((prev)=>{
        const previousMonth = moment(prev).add(1, 'month')
        return previousMonth
      })
    }
  }, [])

  const updateQuarter = useCallback((direction) => {
    if (direction === 'prev') {
      setCurrentQuarter((prev) => {
        if(prev === 1) {
          const prevYear = moment([currentYear,11,28]).subtract(1, 'year').year()
          setCurrentYear(prevYear)
          const prevQuarter = moment([currentYear, 11, 28]).quarter()
          setQuarterRange(getQuarterMonths(prevYear, prevQuarter))
          return prevQuarter
        } else {
          const previousQuarter = prev - 1 
          setQuarterRange(getQuarterMonths(currentYear, previousQuarter))
          return previousQuarter
        }
      })
    } else if (direction === 'next') {
      setCurrentQuarter((prev)=>{
        if(prev === 4) {
          const nextYear = moment([currentYear,11,28]).add(1, 'year').year()
          setCurrentYear(nextYear)
          const nextQuarter = moment([currentYear, 0, 4]).quarter()
          setQuarterRange(getQuarterMonths(nextYear, nextQuarter))
          return nextQuarter
        } else {
          const nextQuarter = prev + 1 
          setQuarterRange(getQuarterMonths(currentYear, nextQuarter))
          return nextQuarter
        }
      })
    }


  }, [currentYear])

  const updateYear = useCallback((direction) => {
    if (direction === 'prev') {
      setCurrentYear((prev) => {
        const incomingYear = moment([prev,11,28]).subtract(1, 'year')
        return incomingYear.year()
      })
    } else if (direction === 'next') {
      setCurrentYear((prev)=>{
        const incomingYear = moment([prev,11,28]).add(1, 'year')
        return incomingYear.year()
      })
    }

  }, [])

  
  return <DatesTypeContext.Provider value={{
    filterTaskType,
    setFilterTaskType,
    filterPerformanceType,
    setFilterPerformanceType,
    currentDay,
    setCurrentDay,
    customFirstDay,
    setCustomFirstDay,
    customSecondDay,
    setCustomSecondDay,
    currentWeek,
    setCurrentWeek,
    rangeWeek,
    setWeekRange,
    currentMonth,
    setCurrentMonth,
    currentQuarter,
    setCurrentQuarter,
    quarterRange,
    setQuarterRange,
    currentYear,
    setCurrentYear,
    lastWeekOfYear,
    setLastWeekOfYear,
    updateWeek,
    updateDay,
    updateCustomFirstDay,
    updateCustomSecondDay,
    updateMonth,
    updateQuarter,
    updateYear
  }}>{children}</DatesTypeContext.Provider>
}

