import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, font13_18, getFramerTransition } from '@/styles'

export const Toast = ({ children, idx, type }) => {

  return (
    <ToastStyled $type={type} $isAbsolute={idx !== 0} aria-live='assertive' variants={variants} initial={'initial'} animate={'animate'} exit='exit'>
      {children}
    </ToastStyled>
  )
}

export const ToastStyled = styled(motion.li)<{ $type: string, $isAbsolute: boolean }>`
  ${font13_18(true, 400)}
  letter-spacing: .5px;
  align-items: center;
  background: ${({ $type }) => $type === 'fail' ? 'var(--color-red-alert)' : 'var(--color-text)'};
  border-radius: 5px 0 5px 0;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, .05);
  color: rgba(255, 255, 255, .8);
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-bottom: 10px;
  min-width: 100px;
  opacity: 1;
  padding: 5px 20px;
  user-select: none;
  width: auto;

  ${mq.greaterThan('tablet')} {
    ${font13_18(false, 400)}
  }

  ${({ $isAbsolute }) => $isAbsolute ? 'position: absolute; top: 0; left: 0;' : ''}

  strong {
    opacity: 1;
  }
`

const variants = {
  initial: (custom) => ({
    opacity: 0,
    x: '100%',
    scale: 1 - (custom * 0.1),
    y: (custom * -5) + 'px',
    transition: {
      ...getFramerTransition()
    }
  }),
  animate: (custom) => ({
    opacity: 1 - (custom * 0.1),
    x: '0%',
    scale: 1 - (custom * 0.1),
    y: (custom * -5) + 'px',
    transition: {
      ...getFramerTransition()
    }
  }),
  exit: (custom) => ({
    opacity: 0,
    x: '100%',
    scale: 1 - (custom * 0.1),
    y: (custom * -5) + 'px',
    transition: {
      ...getFramerTransition()
    }
  })
}
