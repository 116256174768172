import { createContext, useEffect, useState } from 'react'
import type { FC, Dispatch, SetStateAction } from 'react'
import type { Paths, TypeDictionaries, TypeLocales, Localization } from '@/schema'
import { LOCALE } from '@/utils'

// @ts-ignore
import esTranslations from '/public/locales/es.json'

const DEFAULT_LANG = LOCALE.split('-')[0] as TypeLocales

// All translations keys level
export type TypeTranslationsJsonKeys = Paths<typeof esTranslations>

const dictionary:TypeDictionaries = {
  // en: enTranslations,
  // // @ts-ignore
  es: esTranslations
  // @ts-ignore
  // fr: frTranslations,
  // // @ts-ignore
  // de: deTranslations,
  // // @ts-ignore
  // ca: caTranslations,
}

// First level only
type TypeTranslationsJsonRootKeys = keyof typeof esTranslations
type TypeNamespace = TypeTranslationsJsonRootKeys | TypeTranslationsJsonRootKeys[]

// @ts-ignore
interface TranslationLocalization extends Localization {
  namespace: TypeNamespace
}

type TypeGetTranslations = (locale: string, namespace?: TypeNamespace) => TranslationLocalization

// to get translations data, used in getStaticProps of each page
export const getTranslations:TypeGetTranslations = (locale, namespace = null) => {
  const lang = typeof locale === 'string' ? locale.split('-')[0] as TypeLocales : DEFAULT_LANG
  // const dict = await import(`../public/locales/${lang}.json`)
  const translations = {
    layout: dictionary[lang].layout,
    form: dictionary[lang].form,
    commons: dictionary[lang].commons,
    modals: dictionary[lang].modals
  }

  if(typeof namespace === 'string') {
    translations[namespace] = dictionary[lang][namespace]
  } else if(Array.isArray(namespace)) {
    for(let name of namespace) {
      if(typeof name === 'string') translations[name] = dictionary[lang][name]
    }
  }

  return { locale: lang, translations, namespace }
}

interface iTranslationsContextProps {
  readonly localization: Localization;
  readonly setTranslation: Dispatch<SetStateAction<Localization>>
}

const initialLocalization:Localization = {
  locale: DEFAULT_LANG,
  translations: dictionary[DEFAULT_LANG],
  namespace: null
}

export const TranslationsContext = createContext<iTranslationsContextProps>({
  localization: initialLocalization,
  setTranslation: () => null
})

type TypeTranslationProviderProps = {
  localization: Localization,
  children: JSX.Element
}

export const TranslationsProvider: FC<TypeTranslationProviderProps> = ({ localization, children }) => {
  const [localizationState, setLocalizationState] = useState(initialLocalization)
  const routerLocale = LOCALE

  useEffect(() => {
    const locale = routerLocale.split('-')[0]

    if (localization?.locale && (locale !== localization?.locale || localizationState.namespace !== localization.namespace)) {
      setLocalizationState({
        locale: localization?.locale,
        translations: localization?.translations,
        namespace: localization?.namespace,
      })
    }
  }, [routerLocale, localization, localizationState])

  return (
    <TranslationsContext.Provider value={{ localization: localizationState, setTranslation: setLocalizationState }}>
      {children}
    </TranslationsContext.Provider>
  )
}
