import { css } from 'styled-components'
import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / factor}rem`
}

export const vwFont = (size: number, mobile: boolean = true) => rem.get(size, rem[mobile ? 'baseMobile' : 'base'])

export const font10_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(10, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font10_12 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(10, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(12, mobile)};
`

export const font11_13 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(11, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(13, mobile)};
`

export const font12_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(12, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font12_15 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(12, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(15, mobile)};
`

export const font12_19 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(12, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(19.1, mobile)};
`

export const font13_13 = (mobile: boolean = true, weight: 300 | 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(13, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(13, mobile)};
`

export const font13_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(13, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font13_18 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(13, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(18, mobile)};
`

export const font13_19 = (mobile: boolean = true, weight: 300 | 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(13, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(19, mobile)};
`

export const font14_19 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(14, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(19.1, mobile)};
`

export const font14_21 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(14, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(21, mobile)};
`

export const font15_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(15, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font16_16 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(16, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(16, mobile)};
`

export const font16_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(16, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font16_20 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(16, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(20, mobile)};
`

export const font16_25 = (mobile: boolean = true, weight: 300 | 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(16, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(25, mobile)};
`

export const font18_21 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(18, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(21, mobile)};
`

export const font18_25 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(16, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(25, mobile)};
`

export const font20_20 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(20, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(20, mobile)};
`

export const font20_normal = (mobile: boolean = true, weight: 300 | 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(20, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font20_25 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(20, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(25, mobile)};
`

export const font20_28 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(20, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(28, mobile)};
`

export const font22_28 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(22, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(28, mobile)};
`

export const font22_45 = (mobile: boolean = true, weight: 400 | 500 | 600 | 700 = 400) => css`
  font-size: ${vwFont(22, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(45, mobile)};
`

export const font24_16 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(24, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(16, mobile)};
`

export const font24_27 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(22, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(27, mobile)};
`

export const font25_normal = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(25, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font25_31 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(25, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(31, mobile)};
`

export const font25_55 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(25, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(55, mobile)};
`

export const font27_37 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(27, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(37, mobile)};
`

export const font28_32 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(28, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(32, mobile)};
`

export const font30_30 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${rem.get(30, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(30, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font30_38 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${rem.get(30, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(38, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font32_37 = (mobile: boolean = true, weight: 400 | 600 | 700 = 400) => css`
  font-size: ${vwFont(32, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(37, mobile)};
`

export const font40_normal = (mobile: boolean = true, weight: 400 | 600 | 700 | 700 = 400) => css`
  font-size: ${vwFont(40, mobile)};
  font-weight: ${weight};
  line-height: auto;
`

export const font40_46 = (mobile: boolean = true, weight: 400 | 600 | 700 | 700 = 400) => css`
  font-size: ${vwFont(40, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(46, mobile)};
`

export const font50_80 = (mobile: boolean = true, weight: 400 | 600 | 700 | 700 = 400) => css`
  font-size: ${vwFont(50, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(80, mobile)};
`

export const font60_normal = (mobile: boolean = true, weight: 300 | 400 | 600 | 700 | 700 = 400) => css`
  font-size: ${vwFont(60, mobile)};
  font-weight: ${weight};
  line-height: normal;
`

export const font80_80 = (mobile: boolean = true, weight: 400 | 600 | 700 | 700 = 400) => css`
  font-size: ${vwFont(80, mobile)};
  font-weight: ${weight};
  line-height: ${vwFont(80, mobile)};
`

export const font18_normal = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${vwFont(18, mobile)};
  font-weight: ${weight};
  line-height: normal;
`

// PENDING UPDATE to the font[fontSize]_[lineHeight] syntax
export const getFormLabel = (weight: 400|600 = 400) => {
  return css`
    font-size: 10px;
    font-weight: ${weight};
    letter-spacing: .83px;
    line-height: 10px;
    text-transform: uppercase;

    &.active {
      font-size: 18px;
      font-weight: ${weight};
      letter-spacing: 0px;
      line-height: 25px;
      text-transform: initial;
    }
  `
}

export const getP18InputLabel = (weight: 400|600 = 400) => {
  return css`
    font-size: ${rem.get(15)};
    font-weight: ${weight};
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `
}

export const getP18InputHasContentLabel = (weight: 400|600 = 400) => {
  return css`
    font-size: ${rem.get(12)};
    font-weight: ${weight};
    line-height: ${rem.get(10)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(10, rem.base)};
      letter-spacing: -.36px;
    }
  `
}
