import ReactDOM from 'react-dom'
import { useEffect, useState, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Toast as DefaultToast } from './Toast'

let PARENT_ELEMENT = null

const createParentElement = () => {
  if (!PARENT_ELEMENT) {
    const firstScriptElement = document.body.querySelector('script')
    PARENT_ELEMENT = document.getElementById('root-toast')

    PARENT_ELEMENT = document.createElement('div')
    PARENT_ELEMENT.id = 'root-toasts'
    PARENT_ELEMENT.style.cssText = `
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 9999;
    `

    document.body.insertBefore(PARENT_ELEMENT, firstScriptElement)
  }

  return PARENT_ELEMENT
}

const getToast = (toasts, Toast) => {
  let _idx = toasts.length - 1
  const Toasts = []

  for (let idx = 0; idx < toasts.length; ++idx) {
    const { children, id, type } = toasts[idx]

    Toasts.push(<Toast type={type} key={id} idx={_idx}>{children}</Toast>)
    --_idx
  }

  return Toasts
}


const ToastContainer = ({ toasts, Toast = DefaultToast }) => {
  const [parentElement, setElement] = useState(null)

  useEffect(() => {
    setElement(createParentElement())
  }, [])

  const Toasts = useMemo(() => getToast(toasts, Toast), [toasts.length])

  return parentElement ? ReactDOM.createPortal(<ul><AnimatePresence>{Toasts}</AnimatePresence></ul>, parentElement) : <></>
}

let TIMEOUT_ID = null
const DEFAULT_CONF = {
  timeout: 5000
}
export const useToast = ({ timeout } = DEFAULT_CONF) => {
  const [toasts, setToasts] = useState([])

  const handleToastLive = () => {
    if (TIMEOUT_ID) return

    TIMEOUT_ID = setTimeout(() => {
      setToasts([])
      TIMEOUT_ID = null
    }, timeout)
  }

  const addToast = ({ children, type }) => {
    if (toasts.length) return

    setToasts([{ children, id: Date.now(), type: type ? type : 'fail' }])
    handleToastLive()
  }

  return {Container: ToastContainer, addToast, toasts}
}
