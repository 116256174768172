import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState, useEffect } from 'react'
import type { TypeCMSUser } from '@/schema'
import { cookie, getUserDataFromCookie } from '@/utils'


type TypeUser = TypeCMSUser & { token: string }
type TypeState = {
  user: TypeUser
  setUser: Dispatch<SetStateAction<TypeUser>>
}

const initialState: TypeState = {
  user: null,
  setUser: () => { },
}

export const SessionContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

let cookieChecked = false
export const SessionProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<TypeUser>(undefined)

  useEffect(() => {
    const userCookieData = getUserDataFromCookie(cookie.get('DY_TEMPUS_USER'))
    console.log('userCookieData', userCookieData)
    if (!userCookieData) {
      console.log('🟡 PENDING REDIRECT IF PRIVATE PATH')
    } else {
      setUser({ ...userCookieData.user, token: userCookieData.token })
    }

    cookieChecked = true
  }, [])

  useEffect(() => {
    if(cookieChecked && !user) {
      console.log('🟡 PENDING REDIRECT IF PRIVATE PATH LOGOUT OR SESSION EXPIRED')
    }
  }, [user])

  return <SessionContext.Provider value={{ user, setUser }}>{children}</SessionContext.Provider>
}
