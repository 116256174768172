import { TypeLocales } from '@/schema'
import moment from 'moment'

interface ParseDateOptions {
  dateString: string | Date
  includeHour?: boolean
  includeSeconds?: boolean
  includeTimezone?: boolean
  locale?: 'es-ES' | 'en-GB' | 'en-US' | 'fr-FR' | 'de-DE' | 'it-IT' | 'ru-RU'
}

const isValidDate = (dateString) => !isNaN(Date.parse(dateString))

export function parseDateString({ dateString, locale = 'es-ES', includeHour = false, includeSeconds = false, includeTimezone = false }: ParseDateOptions): string {
  if(!isValidDate(dateString)) {
    console.warn('🗓️ dateString passed is not a valid date pattern and cannot be parsed')
    return ''
  }

  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  if (includeHour) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    if(includeSeconds) options.second = '2-digit'
  }

  if (includeTimezone) options.timeZoneName = 'short'

  return date.toLocaleString(locale, options)
}


export function extractDate(dateString: string): string {
  const dateObj = moment(dateString) // Parse the input date string using Moment.js
  const year = dateObj.year()
  const month = dateObj.format('MM') // Format the month with leading zeros
  const day = dateObj.format('DD') // Format the day with leading zeros
  return `${year}-${month}-${day}`
}


export function formatDateToYYYYMMDD(date): string {
  const dateObj = new Date(`${date} UTC`) // Appending UTC ensures the date is parsed as UTC
  if (isNaN(dateObj.getTime())) {
    throw new Error('Invalid date format. Please use RFC2822 or ISO format.')
  }
  return dateObj.toISOString().split('T')[0] // Formats as 'YYYY-MM-DD'
}

export function extractWeekMonthYear(dateString: string, firstDayOfWeek: 'Monday' | 'Sunday' = 'Monday'): { week: number, month: number, year: number } {
  const dateObj = moment(dateString) // Parse the input date string using Moment.js
  const year = dateObj.year()
  const month = dateObj.month() + 1 // Months are zero-indexed in Moment.js
  const week = getWeekNumber(dateObj, firstDayOfWeek)

  return { week, month, year }
}

export function getWeekNumber(date: moment.Moment, firstDayOfWeek: 'Monday' | 'Sunday' = 'Monday'): number {

  const dt = date.clone()

  if (firstDayOfWeek === 'Monday') {
    dt.day(4) // Set to Thursday to handle Monday as the first day of the week
  } else {
    dt.day(3) // Set to Wednesday to handle Sunday as the first day of the week
  }

  const yearStart = date.clone().startOf('year')
  const daysDiff = dt.diff(yearStart, 'days') + 1

  return Math.ceil(daysDiff / 7)
}

export function getWeekDays(year: number, weekNumber: number, firstDayOfWeek: 'Monday' | 'Sunday' = 'Monday'): Date[] {
  if (weekNumber < 1 || weekNumber > 53) {
    throw new Error('Invalid week number. Week number should be between 1 and 53.')
  }

  if (firstDayOfWeek !== 'Monday' && firstDayOfWeek !== 'Sunday') {
    throw new Error('Invalid firstDayOfWeek parameter. It should be either "Monday" or "Sunday".')
  }

  const firstDayOfYear = moment([year, 0, 1])
  const daysToAdd = (weekNumber - 1) * 7

  // Use Moment.js to add days to the first day of the year
  const firstDayOfWeekDate = firstDayOfYear.clone().add(daysToAdd, 'days')

  // Calculate the day offset based on the chosen first day of the week
  let dayOffset = firstDayOfWeek === 'Monday' ? 1 : 0

  // Adjust the first day of the week based on the offset
  const adjustedFirstDayOfWeek = firstDayOfWeekDate.clone().subtract(dayOffset, 'days')

  const weekDays = []
  for (let i = 0; i < 7; i++) {
    const day = adjustedFirstDayOfWeek.clone().add(i, 'days')
    weekDays.push(day.toDate())
  }

  return weekDays
}

export function getQuarterMonths(year, quarter) {
  if (quarter < 1 || quarter > 4) {
    throw new Error('Quarter must be between 1 and 4')
  }

  const startMonth = (quarter - 1) * 3 + 1 // Calculate the first month of the quarter
  const endMonth = startMonth + 2 // Calculate the last month of the quarter

  const startDate = moment(`${year}-${startMonth}-01`)
  const endDate = moment(`${year}-${endMonth}-${moment(`${year}-${endMonth}`).daysInMonth()}`)

  const result = `${startDate.format('MM')} - ${endDate.format('MM')} ${year}`
  return result
}

export function getDateOfMondayOfWeek(year: number, weekNumber: number): Date {
  const isLeapYear = moment([year, 1, 29]).isValid()

  if (weekNumber < 1 || (weekNumber > 52 && (!isLeapYear || weekNumber !== 53))) {
    // Week number is out of valid range for the given year
    return null
  }

  // Create a Moment.js object for January 4th of the year
  const januaryFourth = moment([year, 0, 4])
  januaryFourth.locale('en').weekday(1)

  let mondayOfWeek = januaryFourth.clone().add(weekNumber - 1, 'weeks')

  return mondayOfWeek.toDate()
}


export function getWeekRange(currentYear: number, weekNumber?: number): string {
  let currentDate

  if (weekNumber) {
    currentDate = getDateOfMondayOfWeek(currentYear, weekNumber)
  } else {
    currentDate = moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    })
  }

  const firstDayOfWeek = moment(currentDate).startOf('week').locale('es')
  const lastDayOfWeek = moment(firstDayOfWeek).endOf('week').locale('es')

  const firstDayNumber = firstDayOfWeek.date()
  const lastDayNumber = lastDayOfWeek.date()

  const monthFirstDayOfWeek = firstDayOfWeek.month() + 1 // Months are 0-indexed in moment.js
  const monthLastDayOfWeek = lastDayOfWeek.month() + 1

  const yearFirstDayOfWeek = firstDayOfWeek.year().toString().slice(-2) // Get last two digits of year
  const yearLastDayOfWeek = lastDayOfWeek.year().toString().slice(-2)

  let result

  if (monthFirstDayOfWeek !== monthLastDayOfWeek) {
    result = `${firstDayNumber} - ${lastDayNumber}.${monthLastDayOfWeek}.${yearLastDayOfWeek}`
  } else if (monthFirstDayOfWeek === monthLastDayOfWeek && yearFirstDayOfWeek === yearLastDayOfWeek) {
    result = `${firstDayNumber} - ${lastDayNumber}.${monthFirstDayOfWeek}.${yearFirstDayOfWeek}`
  } else {
    result = `${firstDayNumber} - ${lastDayNumber}.${monthFirstDayOfWeek}.${yearFirstDayOfWeek}`
  }

  return result
}


//DAY
export function groupByDayAndProject(arr: any[]) {
  return arr.reduce((arrGrouped, obj) => {
    const day = extractDate(obj.date)
    const projectName = obj.project[0].name
    if (!arrGrouped[day]) arrGrouped[day] = {}
    if (!arrGrouped[day][projectName]) arrGrouped[day][projectName] = []
    arrGrouped[day][projectName].push(obj)
    return arrGrouped
  }, {})
}

// WEEK

export function getWeek(date: Date) {
  return moment(date).week()
}


export function groupByWeek(arr: any[]) {
  return arr.reduce((arrGrouped, obj) => {
    const week = getWeek(new Date(obj.date))
    if (!arrGrouped[week]) arrGrouped[week] = []
    arrGrouped[week].push(obj)
    return arrGrouped
  }, {})
}

export function groupByDate(arr: any[]) {
  return arr.reduce((arrGrouped, obj) => {
    const date = new Date(obj.date).toISOString().split('T')[0] // Get the date in YYYY-MM-DD format
    if (!arrGrouped[date]) arrGrouped[date] = []
    arrGrouped[date].push(obj)
    return arrGrouped
  }, {})
}

export const getCurrentMonthAbbreviation = (lang: TypeLocales, dateStr:string = undefined): string => {
  const currentDate = new Date(dateStr || Date.now())
  const formatter = new Intl.DateTimeFormat(lang, { month: 'short' });
  return formatter.format(currentDate).toLowerCase();
}

export const getLastTwoDigitsOfYear = (dateStr:string = undefined): number => {
  const currentYear = new Date(dateStr || Date.now()).getFullYear()
  return currentYear % 100;
}
