const defaultTheme = {
  colors: {
    'background': 'rgb(30, 30, 30)',
    'text': 'rgb(255, 255, 255)',
  },
  fonts: {
    // eslint-disable-next-line quotes
    'family': `'Montserrat', sans-serif`,
    // eslint-disable-next-line quotes
    'familyCode': `'Courier New', monospace`,
    'size': 16,
    'lineHeight': 24 / 16,
    'headersize': 20,
    'headerLineHeight': 24 / 20
  }
}

const themeProject = {
  colors: {
    'navbar': '#1B1E34',
    'background': '#141629',
    'text': '#ffffff',
    'black': '#141629',
    'dark_blue': '#566CE3',
    'light_blue': '#91A4EE',
    'super_light_blue': '#F6F8FE',
    'white': '#ffffff',
    'red': '#E82C6B',
    'light_red': '#C5426D',
    'dark_green': '#C2D1AD',
    'light_green': '#CFE2BA',
    'dark_purple': '#BC6886',
    'light_purple': '#D48F9A',
    'dark_yellow': '#E3B67B',
    'light_yellow': '#E5CD87',
    'light_orange': '#FCB058',
    'lavanda':'#7825E3',
    'input_base': '#0374E0',
    'input_error': '#EB005B',
    'input_black': '#000',
  },
  fonts: {
    // eslint-disable-next-line quotes
    primaryFont: `'__Plus_Jakarta_Sans_f44c9f', '__Plus_Jakarta_Sans_Fallback_f44c9f', 'sans-serif'`
  }
}

export const theme = {
  colors: {
    ...defaultTheme.colors,
    ...themeProject.colors
  },
  fonts: {
    ...defaultTheme.fonts,
    ...themeProject.fonts
  }
}

