import { css } from 'styled-components'

const breakpoints = {
  'mobile': '390px',
  'nexus7': '603px',
  'tablet': '768px',
  'ipadpro-12': '1284px',
  'desktop-xs': '992px',
  'desktop': '1440px',
  'desktop-large': '1920px',
  'desktop-wqhd': '2560px'
} as const

type TypeBreakpointKey = keyof typeof breakpoints
type TypeMq = {
  greaterThan: (device: TypeBreakpointKey) => string
  lessThan: (device: TypeBreakpointKey) => string
  between: (fromDevice: TypeBreakpointKey, untilDevice: TypeBreakpointKey) => string
}

function pxToEm(breakpoint: TypeBreakpointKey, baseRemPx = 16): string {
  const breakpointValue = breakpoints[breakpoint]
  if (!breakpointValue) throw new Error(`Breakpoint "${breakpoint}" not found.`)

  const parsedValue = parseInt(breakpointValue, 10)
  const emValue = parsedValue / baseRemPx // Assuming 1rem = 16px

  return `${emValue}em`
}

export const mq: TypeMq = {
  greaterThan: (device) => `@media (min-width: ${pxToEm(device)})`,
  lessThan: (device) => `@media (max-width: ${pxToEm(device)})`,
  between: (fromDevice, untilDevice) => `@media (min-width: ${pxToEm(fromDevice)}) and (max-width: ${pxToEm(untilDevice)})`
}

export const vw = (size: number, breakpoint: TypeBreakpointKey | number, unit = 'vw') => {
  const screenWidth = breakpoints[breakpoint] ?? breakpoint
  return `${(100 * size / parseInt(screenWidth))}${unit}`
}

export const vh = (size, breakpoint: TypeBreakpointKey | number) => {
  const screenWidth = breakpoints[breakpoint] ?? breakpoint
  return `${(100 * parseInt(size) / parseInt(screenWidth))}vh`
}

export const getEasing = (duration = 300) => (
  {
    duration,
    ease: [.25, .1, .25, 1]
  }
)

export const getTransition = (duration = .3) => ({
  duration,
  type: 'tween',
  ease: [.25, .1, .25, 1]
})

export type Col = 'auto' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
interface iColumns {
  number: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  width: number
}

export interface iGrid {
  readonly mobile?: {
    maxWidth: number
    gutter: { width: number }
    columns: iColumns
  },
  readonly tablet?: {
    maxWidth: number
    gutter: { width: number }
    columns: iColumns
  },
  readonly desktop?: {
    maxWidth: number
    gutter: { width: number }
    columns: iColumns
  }
}

export const defaultGrid: iGrid = {
  mobile: {
    maxWidth: 414, // mobile
    gutter: { width: 20 },
    columns: {
      number: 6,
      width: 45
    }
  },
  tablet: {
    maxWidth: 768,
    gutter: { width: 18 },
    columns: {
      number: 12,
      width: 45
    }
  },
  desktop: {
    maxWidth: 1440,
    gutter: { width: 30 },
    columns: {
      number: 12,
      width: 87
    }
  }
}

export const fullWidth = ({ mobile = defaultGrid.mobile, tablet = defaultGrid.tablet, desktop = defaultGrid.desktop }) => {
  return css`
    transform: translateX(-${vw((mobile.maxWidth - (mobile.columns.width + mobile.gutter.width) * mobile.columns.number), 'mobile')});
    width: 100vw;

    @media (min-width: 768px) {
      transform: translateX(-${vw((tablet.maxWidth - (tablet.columns.width + tablet.gutter.width) * tablet.columns.number), 'tablet')});
    }

    @media (min-width: 1440px) {
      transform: translateX(-${vw((desktop.maxWidth - (desktop.columns.width + desktop.gutter.width) * desktop.columns.number), 'desktop')});
    }
  `
}

export const fullWidthMargins = ({ mobile = defaultGrid.mobile, tablet = defaultGrid.tablet, desktop = defaultGrid.desktop }) => {
  return css`
    margin-left: ${vw((mobile.maxWidth - (mobile.columns.width + mobile.gutter.width) * mobile.columns.number), 'mobile')};

    @media (min-width: 768px) {
      margin-left: ${vw((tablet.maxWidth - (tablet.columns.width + tablet.gutter.width) * tablet.columns.number), 'tablet')};
    }

    @media (min-width: 1440px) {
      margin-left: ${vw((desktop.maxWidth - (desktop.columns.width + desktop.gutter.width) * desktop.columns.number), 'desktop')};
    }
  `
}

export const designGrid = ({ mobile = defaultGrid.mobile, tablet = defaultGrid.tablet, desktop = defaultGrid.desktop }: iGrid) => {
  return css`
    display: grid;
    grid-template-columns: repeat(${mobile.columns.number}, ${vw(mobile.columns.width, mobile.maxWidth, '%')});
    grid-column-gap: ${vw(mobile.gutter.width, mobile.maxWidth, '%')};
    grid-row-gap: 0;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      grid-column-gap: ${vw(tablet.gutter.width, tablet.maxWidth, '%')};
      grid-template-columns: repeat(${tablet.columns.number}, ${vw(tablet.columns.width, tablet.maxWidth, '%')});
    }

    @media (min-width: 1440px) {
      grid-column-gap: ${vw(desktop.gutter.width, desktop.maxWidth, '%')};
      grid-template-columns: repeat(${desktop.columns.number}, ${vw(desktop.columns.width, desktop.maxWidth, '%')});
    }
  `
}

export const innerGrid = (config) => {
  const { gutter, columns, maxWidth } = config

  // Determine the fraction-based grid template columns value
  // Each column width is treated as a fraction of the total grid width
  const gridTemplateColumns = `repeat(${columns.number}, minmax(0, 1fr))`

  // Use the gutter width as the grid gap
  return css`
    display: grid;
    grid-column-gap: ${vw(gutter.width, maxWidth, '%')};
    grid-template-columns: ${gridTemplateColumns};
    width: 100%;
  `
}

// ${innerGrid({
//   maxWidth: 1440,
//   gutter: {
//     width: 20,
//   },
//   columns: {
//     number: 12,
//     width: 97,
//   },
// })}
